var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _vm._e(),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              this.data != null && !_vm.api.isLoading
                ? _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      search: this.searchData,
                      items: this.filteredData,
                      headers: this.dataHeader,
                      dense: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.created_at",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.convertTimeZone(item.created_at)
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "item.company_name",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "PageCompaniesDetail",
                                      params: { id: item.company_id },
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.company_name) + " ")]
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.Index",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " + _vm._s(_vm.data.indexOf(item) + 1) + " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.invoice_expired_at",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.convertTimeZone(item.invoice_expired_at)
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "top",
                          fn: function () {
                            return [
                              _c(
                                "v-toolbar",
                                {
                                  staticClass: "py-3",
                                  attrs: {
                                    height: "auto",
                                    width: "auto",
                                    flat: "",
                                  },
                                },
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "mt-3 mx-3" },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-toolbar-title",
                                                {
                                                  staticClass:
                                                    "font-weight-bold text-h4 mt-3 mb-6 ml-5",
                                                },
                                                [_vm._v(" Companies ")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { staticClass: "ml-5" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-start  ",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "tet-h7 grey--text",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Subscription Date : "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        { staticClass: "px-0" },
                                                        [
                                                          _c("v-text-field", {
                                                            staticStyle: {
                                                              "border-radius":
                                                                "4px 0px 0px 4px",
                                                            },
                                                            attrs: {
                                                              dense: "",
                                                              outlined: "",
                                                              "prepend-inner-icon":
                                                                "mdi-calendar",
                                                              label:
                                                                "Start Date...",
                                                            },
                                                            on: {
                                                              "click:prepend-inner":
                                                                function () {
                                                                  _vm.isStartDate = true
                                                                },
                                                              click:
                                                                function () {
                                                                  _vm.isStartDate = true
                                                                },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.startDate,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.startDate =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "startDate",
                                                            },
                                                          }),
                                                          _c(
                                                            "v-dialog",
                                                            {
                                                              attrs: {
                                                                height: "auto",
                                                                width: "auto",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.isStartDate,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.isStartDate =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "isStartDate",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-card",
                                                                {
                                                                  staticClass:
                                                                    "pa-3",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex flex-column",
                                                                    },
                                                                    [
                                                                      _c("p", [
                                                                        _vm._v(
                                                                          " Start Date "
                                                                        ),
                                                                      ]),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "v-date-picker",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  flat: "",
                                                                                  landscape: true,
                                                                                  reactive: true,
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm.startDate,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.startDate =
                                                                                        $$v
                                                                                    },
                                                                                  expression:
                                                                                    "startDate",
                                                                                },
                                                                            }
                                                                          ),
                                                                          _vm._t(
                                                                            "footer",
                                                                            function () {
                                                                              return [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "d-flex justify-end white",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-btn",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            color:
                                                                                              "primary",
                                                                                            plain:
                                                                                              "",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function () {
                                                                                              _vm.isStartDate = false
                                                                                              _vm.startDate =
                                                                                                null
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " Cancel "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "v-btn",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            color:
                                                                                              "primary",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function () {
                                                                                              _vm.isStartDate = false
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " Confirm "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            }
                                                                          ),
                                                                        ],
                                                                        2
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        { staticClass: "px-0" },
                                                        [
                                                          _c("v-text-field", {
                                                            staticStyle: {
                                                              "border-radius":
                                                                "0px",
                                                            },
                                                            attrs: {
                                                              dense: "",
                                                              outlined: "",
                                                              "prepend-inner-icon":
                                                                "mdi-calendar",
                                                              label:
                                                                "End Date...",
                                                            },
                                                            on: {
                                                              "click:prepend-inner":
                                                                function () {
                                                                  _vm.isEndDate = true
                                                                },
                                                              click:
                                                                function () {
                                                                  _vm.isEndDate = true
                                                                },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.endDate,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.endDate =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "endDate",
                                                            },
                                                          }),
                                                          _c(
                                                            "v-dialog",
                                                            {
                                                              attrs: {
                                                                height: "auto",
                                                                width: "auto",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.isEndDate,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.isEndDate =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "isEndDate",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-card",
                                                                {
                                                                  staticClass:
                                                                    "pa-3",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex flex-column",
                                                                    },
                                                                    [
                                                                      _c("p", [
                                                                        _vm._v(
                                                                          " End Date "
                                                                        ),
                                                                      ]),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "v-date-picker",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  flat: "",
                                                                                  landscape: true,
                                                                                  reactive: true,
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm.endDate,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.endDate =
                                                                                        $$v
                                                                                    },
                                                                                  expression:
                                                                                    "endDate",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      attrs: {
                                                                        name: "footer",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex justify-end my-3",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "primary",
                                                                                  plain:
                                                                                    "",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function () {
                                                                                    _vm.isEndDate = false
                                                                                    _vm.endDate =
                                                                                      null
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " Cancel "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "primary",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function () {
                                                                                    _vm.isEndDate = false
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " Confirm "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { staticClass: " mr-6 " },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-start ",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-h7 grey--text",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Expired Date : "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        { staticClass: "px-0" },
                                                        [
                                                          _c("v-text-field", {
                                                            staticStyle: {
                                                              "border-radius":
                                                                "0px",
                                                            },
                                                            attrs: {
                                                              dense: "",
                                                              outlined: "",
                                                              "prepend-inner-icon":
                                                                "mdi-calendar",
                                                              label:
                                                                "Start Date...",
                                                            },
                                                            on: {
                                                              "click:prepend-inner":
                                                                function () {
                                                                  _vm.isStartDateExpired = true
                                                                },
                                                              click:
                                                                function () {
                                                                  _vm.isStartDateExpired = true
                                                                },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.startDateExpired,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.startDateExpired =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "startDateExpired",
                                                            },
                                                          }),
                                                          _c(
                                                            "v-dialog",
                                                            {
                                                              attrs: {
                                                                height: "auto",
                                                                width: "auto",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.isStartDateExpired,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.isStartDateExpired =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "isStartDateExpired",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-card",
                                                                {
                                                                  staticClass:
                                                                    "pa-3",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex flex-column",
                                                                    },
                                                                    [
                                                                      _c("p", [
                                                                        _vm._v(
                                                                          " Start Date "
                                                                        ),
                                                                      ]),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "v-date-picker",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  flat: "",
                                                                                  landscape: true,
                                                                                  reactive: true,
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm.startDateExpired,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.startDateExpired =
                                                                                        $$v
                                                                                    },
                                                                                  expression:
                                                                                    "startDateExpired",
                                                                                },
                                                                            }
                                                                          ),
                                                                          _vm._t(
                                                                            "footer",
                                                                            function () {
                                                                              return [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "d-flex justify-end white",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-btn",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            color:
                                                                                              "primary",
                                                                                            plain:
                                                                                              "",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function () {
                                                                                              _vm.isStartDateExpired = false
                                                                                              _vm.startDate =
                                                                                                null
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " Cancel "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "v-btn",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            color:
                                                                                              "primary",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function () {
                                                                                              _vm.isStartDateExpired = false
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " Confirm "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            }
                                                                          ),
                                                                        ],
                                                                        2
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        { staticClass: "px-0" },
                                                        [
                                                          _c("v-text-field", {
                                                            staticStyle: {
                                                              "border-radius":
                                                                "0px 4px 4px 0px",
                                                            },
                                                            attrs: {
                                                              dense: "",
                                                              outlined: "",
                                                              "prepend-inner-icon":
                                                                "mdi-calendar",
                                                              label:
                                                                "End Date...",
                                                            },
                                                            on: {
                                                              "click:prepend-inner":
                                                                function () {
                                                                  _vm.isEndDateExpired = true
                                                                },
                                                              click:
                                                                function () {
                                                                  _vm.isEndDateExpired = true
                                                                },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.endDateExpired,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.endDateExpired =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "endDateExpired",
                                                            },
                                                          }),
                                                          _c(
                                                            "v-dialog",
                                                            {
                                                              attrs: {
                                                                height: "auto",
                                                                width: "auto",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.isEndDateExpired,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.isEndDateExpired =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "isEndDateExpired",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-card",
                                                                {
                                                                  staticClass:
                                                                    "pa-3",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex flex-column",
                                                                    },
                                                                    [
                                                                      _c("p", [
                                                                        _vm._v(
                                                                          " End Date "
                                                                        ),
                                                                      ]),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "v-date-picker",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  flat: "",
                                                                                  landscape: true,
                                                                                  reactive: true,
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm.endDateExpired,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.endDateExpired =
                                                                                        $$v
                                                                                    },
                                                                                  expression:
                                                                                    "endDateExpired",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      attrs: {
                                                                        name: "footer",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex justify-end my-3",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "primary",
                                                                                  plain:
                                                                                    "",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function () {
                                                                                    _vm.isEndDateExpired = false
                                                                                    _vm.endDateExpired =
                                                                                      null
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " Cancel "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "primary",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function () {
                                                                                    _vm.isEndDateExpired = false
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " Confirm "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-end mr-6 mb-6 pb-6",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "px-2" },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                        outlined: "",
                                                      },
                                                      on: {
                                                        click: _vm.resetFilter,
                                                      },
                                                    },
                                                    [_vm._v(" Reset Filter ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: { color: "primary" },
                                                  on: { click: _vm.filterDate },
                                                },
                                                [_vm._v(" Filter ")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { staticClass: "mt-5" },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  outlined: "",
                                                  label: "Search...",
                                                },
                                                model: {
                                                  value: _vm.searchData,
                                                  callback: function ($$v) {
                                                    _vm.searchData = $$v
                                                  },
                                                  expression: "searchData",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "d-flex justify-end",
                                            },
                                            [
                                              _c(
                                                "JsonCSV",
                                                {
                                                  attrs: {
                                                    data: _vm.downloadData,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                      },
                                                    },
                                                    [_vm._v(" Download ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }